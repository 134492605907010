.no-underline {
  text-decoration: none !important;
}

.mywm__account_link {
  color: #B2A87E;
  text-decoration: none;
  font-weight: bold;
}

.mywm__account_link:hover {
  color: #B2A87E;
  text-decoration: underline;
}

.ReactTable {
  border: 2px solid #B2A87E;
}

.ReactTable .rt-th {
  background-color: #B2A87E;
}

@media (max-width: 850px) {
  .text-end {
    text-align: left!important;
  }
}
